/* eslint-disable react/style-prop-object */
import { IntlProvider, FormattedNumber } from 'react-intl';
import './App.css';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get('name') ?? "N/A";
  const distributor = queryParams.get('distributor') ?? "N/A";
  
  const min = parseInt(queryParams.get('min') ?? '0', 10);
  const max = parseInt(queryParams.get('max') ?? '0', 10);

  const discounts = queryParams.get('discounts')?.split(',') ?? [];

  return (
    <IntlProvider locale='pt'>
    <div className="App">
       <img src="images/bg.png" alt="" id="img" />

       <div id='client-info'>
        <p><span>Nome:</span> {name}</p>
        <p><span>Distribuidora:</span> {distributor}</p>
       </div>

       <div id='min-max'>
          <div id="min">
            <FormattedNumber value={min} style="currency" currency="BRL" />
          </div>
          <div id="max">
            <FormattedNumber value={max} style="currency" currency="BRL" />
          </div>
        </div>

        <div id="discounts">
          {discounts.map(discount => <div>{discount}</div>)}
        </div>
    </div>
    </IntlProvider>
  );
}

export default App;
