// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  height:297mm;
  width:210mm;
  background-color: #000;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.App {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0;
}

#img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#client-info {
  position: absolute;
  z-index: 2;
  top: 32px;
  left: 240px;
  color: white;
  font-size: 16px;
  font-family: 'Arial';
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

#client-info p {
  margin: 0;
}

#client-info span {
  font-weight: bold;
}

#min-max {
  position: absolute;
  z-index: 2;
  top: 282px;
  left: 62px;
  color: black;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  color: white;
}

#max {
  left: 244px;
  position: absolute;
}

#discounts {
  position: absolute;
  z-index: 2;
  top: 591px;
  left: 465px;
  color: #666;
  font-size: 16px;
  display: flex;
  gap: 46px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,cAAc;EACd,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,WAAW;EACX,YAAY;EACZ,eAAe;EACf,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,UAAU;EACV,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,WAAW;EACX,WAAW;EACX,eAAe;EACf,aAAa;EACb,SAAS;AACX","sourcesContent":["#root {\n  height:297mm;\n  width:210mm;\n  background-color: #000;\n  margin: 0 auto;\n  padding: 0;\n  position: relative;\n}\n\n.App {\n  text-align: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  margin: 0 0;\n  padding: 0;\n}\n\n#img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n#client-info {\n  position: absolute;\n  z-index: 2;\n  top: 32px;\n  left: 240px;\n  color: white;\n  font-size: 16px;\n  font-family: 'Arial';\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  gap: 4px;\n}\n\n#client-info p {\n  margin: 0;\n}\n\n#client-info span {\n  font-weight: bold;\n}\n\n#min-max {\n  position: absolute;\n  z-index: 2;\n  top: 282px;\n  left: 62px;\n  color: black;\n  font-size: 24px;\n  font-weight: bold;\n  display: flex;\n  color: white;\n}\n\n#max {\n  left: 244px;\n  position: absolute;\n}\n\n#discounts {\n  position: absolute;\n  z-index: 2;\n  top: 591px;\n  left: 465px;\n  color: #666;\n  font-size: 16px;\n  display: flex;\n  gap: 46px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
